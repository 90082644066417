import React, {useState} from 'react';
import axios from 'axios';
import { setUserSession, setTrust, GetTrustSettings, getTrust, setUserSettings } from './Utils/Common';
import PublicRoute from './Utils/PublicRoute';
import {Link} from 'react-router-dom';
import './login.css';
import ForgotPassword from './components/ForgotPassword';
import Register from './components/Register';
import logo from './media/OB_logo.png';
import jwt_decode from 'jwt-decode';

function Login(props) {
  const [loading, setLoading] = useState(false);
  const username = useFormInput('');
  const password = useFormInput('');
  const [error, setError] = useState(null);

  // handle button click of login form
  const handleLogin = async () => {
    setError(null);
    setLoading(true);

    await axios
      .post((process.env.REACT_APP_APIURL || '') + 'auth/signin', {email: username.value, password: password.value})
      .then(async (response) => {
        setLoading(false);
        setUserSession(response.data, username.value);
        var decoded = jwt_decode(response.data);
        const valuesArray = JSON.parse(decoded.TrustAccess);
        if (valuesArray.length === 1) {
          let trust = {
            trustId: valuesArray[0].TrustId,
            trustName: valuesArray[0].TrustName,
          };
            setTrust(trust);
            let UserSettings = {
                defaultVerbosityLevel: valuesArray[0].DefaultVerbosityLevel,
                canChooseAllLocation: valuesArray[0].CanChooseAllLocation,
                canCreateTrustProfiles: valuesArray[0].CanCreateTrustProfiles
            }
            setUserSettings(UserSettings)
          let trustSettings = await GetTrustSettings(getTrust().trustId);
          if (trustSettings.softwareProvider === 'Allocate') {
            props.history.push('/wardguardian');
          } else {
            props.history.push('/DataQuality');
          }
        } else if (valuesArray.length > 1) {
          props.history.push('/trustpicker');
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response == null) setError('Something went wrong. Please try again later.');
        else if (error.response.status === 404) setError(error.response.data);
        else setError('Something went wrong. Please try again later.');
      });
  };

  const italicText = {
    fontStyle: 'italic',
  };

  return (
    <div className="loginbackground">
      <div className="loginbody">
        <div className="oceansbluecentrallogo">
          <span>
            <span>Ward Guardian Compliance Console</span>
          </span>
        </div>
        <div className="login">
          <div className="login-screen">
            <div className="app-title">
              <h1 className="h1">Log in</h1>
              <img className="oblogologin" src={logo} alt="Oceans Blue Logo" />
            </div>

            <div className="login-form">
              <div className="control-group">
                <input
                  type="text"
                  {...username}
                  autoComplete="new-password"
                  className="login-field"
                  placeholder="Username"
                  id="login-name"></input>
                <label className="login-field-icon fui-user" htmlFor="login-name"></label>
              </div>
              <div className="control-group">
                <input
                  type="password"
                  {...password}
                  autoComplete="new-password"
                  className="login-field"
                  placeholder="Password"
                  id="login-pass"></input>
                <label className="login-field-icon fui-lock" htmlFor="login-pass"></label>
              </div>
              {error && (
                <>
                  <span style={{color: 'red'}}>{error}</span>
                  <br />
                </>
              )}
              <br />
              <div className="control-group">
                <Link activeclassname="active" to="/Register">
                  Click here to register
                </Link>
                <PublicRoute path="/Register" component={Register} />
              </div>
              <div className="button-group">
                <Link className="linknexttobutton" activeclassname="active" to="/ForgotPassword">
                  Forgotten password?
                </Link>
                <PublicRoute path="/ForgotPassword" component={ForgotPassword} />
                <input
                  className="btnright"
                  id="submit"
                  type="button"
                  value={loading ? 'Loading...' : 'Login'}
                  onClick={handleLogin}
                  disabled={loading}
                />
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
      <span className="pintobottom">Application Version {process.env.REACT_APP_APPVERSION}</span>
    </div>
  );
}

const useFormInput = (initialValue) => {
  const [value, setValue] = useState(initialValue);

  const handleChange = (e) => {
    setValue(e.target.value);
  };
  return {
    value,
    onChange: handleChange,
  };
};

export default Login;
